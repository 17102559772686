<template>
  <div>
    <v-dialog v-model="dialog" persistent width="1100">
      <v-card>
        <v-card-title>Print Summary </v-card-title>
        <v-card-text>
          <div class="">
            <v-select
              label="Problem"
              :items="problems"
              item-text="problem_description"
              item-value="id"
              v-model="selected_problem"
            ></v-select>
            <div class="d-flex">
              <v-btn
                small
                color=""
                class="mt-5 mr-2"
                @click="get_latest_invoices"
                :loading="loading"
                >Get Invoices</v-btn
              >
              <p class="mt-6 ml-6">
                Selected Invoice:
                <strong>
                  {{
                    selected_invoice ? selected_invoice.invoice_code : ""
                  }}</strong
                >
              </p>
            </div>
          </div>
          <v-data-table :items="invoices" :headers="invoice_headers">
            <template v-slot:item.action="{ item }">
              <v-btn color="primary" small @click="select_invoice(item)"
                >Select</v-btn
              >
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions class="d-flex">
          <v-btn @click="print" color="primary" class="ml-2">Print</v-btn>
          <v-btn @click="print_without_invoice" color="yellow" class="ml-2"
            >Print w/o Invoice</v-btn
          >

          <v-btn @click="$emit('close')" class="ml-2"> Close</v-btn>

          <v-spacer></v-spacer>
          <p class="caption grey--text">summaryModal.vue</p>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";

import { mapGetters, mapActions } from "vuex";

export default {
  props: ["customer_id", "problems"],
  data() {
    return {
      show_update_modal: false,
      show_add_invoice: false,
      dialog: true,
      problems_list: [],
      selected_problem: null,
      selected_invoice: null,
      loading: false,
      invoices: [],
      invoice_headers: [
        { text: "", value: "action" },
        { text: "ID", value: "id" },
        { text: "Invoice Code", value: "invoice_code" },
        { text: "Date", value: "invoice_date" },
        { text: "Total Amount", value: "total_amount" },
        { text: "Discount", value: "discount" },
        { text: "Balance", value: "balance" },
        { text: "Remarks", value: "purpose" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      customers: "customer/customers",
      default_server_url: "auth/default_server_url",
      user: "auth/user",
    }),
  },
  methods: {
    select_invoice(item) {
      this.selected_invoice = item;
    },
    get_latest_invoices() {
      this.loading = true;
      axios
        .get("/get_customer_invoices/" + this.customer_id)
        .then((response) => {
          // console.log(response.data);
          this.invoices = response.data;
          this.loading = false;
        });

      console.log(this.invoices);
    },
    print() {
      // console.log(this.selected_problem, this.selected_invoice);
      if (this.selected_invoice == null || this.selected_problem == null) {
        return alert("No invoice or problem selected");
      }
      var url =
        this.default_server_url +
        "/work_history/" +
        this.selected_problem +
        "/" +
        this.selected_invoice.id +
        "/" +
        this.user.id;
      window.open(url);
    },

    print_without_invoice() {
      var url =
        this.default_server_url +
        "/work_history/" +
        this.selected_problem +
        "/" +
        0 +
        "/" +
        this.user.id;
      window.open(url);
    },

    ...mapActions({
      get_invoices: "invoice/get_invoices",
    }),
    close(type) {
      if (type == "add_invoice") return (this.show_add_invoice = false);
      if (type == "update_modal") return (this.show_update_modal = false);
    },
    add_to_list() {
      console.log(this.selected_problem);
      this.problems_list.push(this.selected_problem);
    },
    print_job_order() {
      var url =
        this.default_server_url +
        "/print_job_order_slip/" +
        JSON.stringify(this.problems_list) +
        "/" +
        this.item.id;
      window.open(url);
    },
  },
  components: {
    // UpdateUnitModal: () => import("@/components/Unit/updateUnitModal.vue"),
    // UpdateUnitModal,
  },
  created() {},
};
</script>

<style lang="scss" scoped></style>
