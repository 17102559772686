<template>
  <v-container class="mt-16">
    <div>
      <v-card flat>
        <v-card-title class="d-flex">
          <h2>Units</h2>
          <v-spacer></v-spacer>
          <add-unit-modal />
          <v-btn
            color=""
            class=""
            icon
            x-large
            @click="refresh"
            :loading="loading"
            ><v-icon>mdi-refresh</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text>
          <v-text-field
            prepend-icon="mdi-magnify"
            label="Search"
            v-model="search"
          ></v-text-field>

          <v-data-table
            :loading="loading"
            :search="search"
            :items="all_units"
            :headers="headers"
          >
            <template v-slot:item.actions="{ item }">
              <div class="d-flex">
                <v-btn
                  @click="show_modal(['problem_modal', item])"
                  color="primary"
                  small
                  icon
                  class="mt-1"
                  ><v-icon>mdi-list-box</v-icon></v-btn
                >
                <v-btn
                  class="ml-2"
                  color="warning"
                  @click="show_modal(['update_unit_modal', item])"
                  icon
                >
                  <v-icon class="mr-2">mdi-pencil</v-icon></v-btn
                >
                <v-btn
                  small
                  class=""
                  color="success"
                  @click="show_modal(['summary_modal', item])"
                  icon
                >
                  <v-icon class="">mdi-note-text-outline</v-icon></v-btn
                >

                <v-btn
                  icon
                  color="red"
                  @click="del(item)"
                  :loading="loading"
                  v-if="user.access_level >= 4"
                  ><v-icon>mdi-delete</v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>

          <summary-modal
            v-if="show_summary_modal"
            :customer_id="selected_item.customer.id"
            :problems="selected_item.problems"
            @close="show_summary_modal = false"
          />

          <update_unit_modal
            :unit="selected_item"
            v-if="show_update_unit_modal"
            @close="show_update_unit_modal = false"
          />

          <problems-modal
            :item="selected_item"
            v-if="show_problems_modal"
            @close="show_problems_modal = false"
          />
        </v-card-text>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
// import { defineAsyncComponent } from "vue";
import SummaryModal from "../components/Problems/summaryModal.vue";
import AddUnitModal from "../components/Unit/addUnitModal.vue";

export default {
  components: {
    problemsModal: () => import("@/components/Problems/problemsModal.vue"),
    update_unit_modal: () => import("@/components/Unit/updateUnitModal.vue"),
    AddUnitModal,
    SummaryModal,
  },
  data() {
    return {
      selected_item: [],
      show_problems_modal: false,
      show_update_unit_modal: false,
      show_summary_modal: false,

      units: [],
      loading: false,
      search: "",
      ready: false,
      headers: [
        { text: "", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Customer Name", value: "customer.customer_name" },
        { text: "Unit Model", value: "unit_model" },
        { text: "Unit Type", value: "unit_type" },
        { text: "Unit Brand", value: "unit_brand" },
        { text: "Serial Number", value: "serial_no" },
        { text: "Date Received", value: "date_received" },
        { text: "Date Picked Up", value: "picked_up_date" },
        { text: "Picked up by:", value: "picked_up_by" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      all_units: "unit/all_units",
      user: "auth/user",
    }),
  },
  methods: {
    show_modal(request) {
      if (request[0] == "update_unit_modal") this.show_update_unit_modal = true;
      if (request[0] == "problem_modal") this.show_problems_modal = true;
      if (request[0] == "summary_modal") this.show_summary_modal = true;
      this.selected_item = request[1];
    },
    ...mapActions({
      get_all_units: "unit/get_all_units",
    }),
    del(item) {
      console.log(item);
    },
    refresh() {
      this.loading = true;
      this.get_all_units().then(() => {
        this.loading = false;
      });
    },
  },
  created() {
    if (this.all_units.length <= 0) {
      this.loading = true;
      this.get_all_units().then(() => {
        this.loading = false;
      });
    }
  },
};
</script>

<style></style>
